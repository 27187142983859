<template>
    <section class="data-table location-table">
        <enso-table class="is-paddingless" id="locations"
                    @viewMedias="viewMedias">
            <template v-slot:image="{ row }">
                <div v-if="row.image" class="flex-centered">
                    <figure class="image is-32x32 flex-centered" style="margin: 0 auto;">
                        <img :src="'/storage/' + row.image" alt="Placeholder image">
                    </figure>
                </div>
            </template>
        </enso-table>
    </section>
</template>

<script>
import { EnsoTable } from '@enso-ui/tables/bulma';

export default {
    name: 'Index',
    components: { EnsoTable },
    inject: ['route'],
    methods: {
        viewMedias(row) {
            this.$router.push({
                name: 'locations.medias.index',
                params: { location: row.id },
            });
        },
    },
};
</script>
